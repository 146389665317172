import * as React from "react";
import { Component, useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import Helper from "../utils/Helper";


import {
  getRequestOptions,
  apiBaseUrl,
  homePageApiUrl,
  fBDImageBaseUrl,
  eventImageBaseUrl,
} from "../config/constant";
import {
  setUserCurrLoc,
  getUserCurrLoc,
  getUser,
  getToken,
  removeUserSession,
} from "../utils/UserAuthenticate";
import Carousel, { Modal, ModalGateway } from 'react-images'

const Hangout_ph = () => {
  const [hangout, setHangout] = useState([]);
  const [n1, setN1] = useState();
  const [n2, setN2] = useState();
  const slider1 = useRef();
  const slider2 = useRef();
  const [slidderImages, setSliderImages] = useState([])
  useEffect(() => {
    Helper.checkCurrentUserLegalAge().then((res) => {
      if (res.legalAge === "No") {
        window.location = "/homepage";
      }
    });
    if (!getUserCurrLoc()) {
      setUserCurrLoc();
    }
    getData();
    //removeUserSession();
  }, []);

  const getData = async () => {
    try {
      // setIsloading(true);
      // here Api call for Home page
      let result = await fetch(apiBaseUrl + homePageApiUrl, getRequestOptions);
      if (result) {
        result = await result.json();

        setHangout(result.response.result.hangout);
        let hangoutImgArrayByType = [];
      
        // Get the hangout types present in the result.response.result.hangout
        const validHangoutTypes = result.response.result.hangout.map(item => item.type);
      
        // Organize hangout images by type and filter to match hangout types
        result.response.result.hangoutImgList.forEach(hangout => {
          const { type, ...rest } = hangout;
      
          // Only proceed if the type exists in the valid hangout types
          if (validHangoutTypes.includes(type)) {
            // Find the index of the hangout type in the array
            const index = hangoutImgArrayByType.findIndex(item => item && item.type === type);
      
            if (index === -1) {
              // If the type is not found, create a new entry
              hangoutImgArrayByType.push({ type, data: [rest] });
            } else {
              // If the type exists, append the new image data to it
              hangoutImgArrayByType[index].data.push(rest);
            }
          }
        });
      
        // Set the updated slider images to match the filtered hangout types
        setSliderImages(hangoutImgArrayByType);

      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openModal = (groupIndex, imageIndex) => {
    setSelectedGroupIndex(groupIndex);
    setSelectedImageIndex(imageIndex);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <Slider
        asNavFor={n1}
        ref={(slider2) => setN1(slider2)}
        slidesToShow={2}
        rows={2}
        swipeToSlide={true}
        focusOnSelect={true}
        dots={true}
        speed={2000}
        autoplay={true}
      >
{slidderImages && slidderImages.map((group, groupIndex) => (
  <div key={groupIndex} className="slideHangOut">
    <div className="d_product_box">
      <figure className="d_product_img mb-0">
        {/* Display the first image of each group as a thumbnail */}
        <img
          src={group.data[0].hangoutImage}
          alt={`Image ${groupIndex + 1}`}
          onClick={() => openModal(groupIndex, 0)} // Open the modal on click
        />
      </figure>
      <p className="d_product_title">{group.type}</p>
    </div>
  </div>
))}


      </Slider>

      {/* ModalGateway wraps the Modal to handle mounting and unmounting */}
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={closeModal}>
            <Carousel
              currentIndex={selectedImageIndex}
              views={
                slidderImages[selectedGroupIndex]?.data
                  ? slidderImages[selectedGroupIndex].data.map(hangoutItem => ({
                      source: hangoutItem.hangoutImage,
                      caption: hangoutItem.hangoutName || 'Default Caption'
                    }))
                  : []
              }
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default Hangout_ph;