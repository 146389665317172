import React, { useState ,useEffect} from "react";
import { Modal, Button } from "react-bootstrap";
import axios from 'axios';
import Helper from "../utils/Helper";
import { apiBaseUrl } from "../config/constant";
import { setSessionForModal,getSessionForModal } from "../utils/Helper";

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { json, Link ,useNavigate } from "react-router-dom";
import { Diversity2Outlined } from "@mui/icons-material";
import { findRestaurantPetpoojaIdByPincode } from "./common";

const ModalComponent = () => {
  const navigate = useNavigate();
  const check = getSessionForModal("longitude")
  const [showModal, setShowModal] = useState(!check);
  const [showModalIncorrectPIn,setShowModalIncorrectPIn]=useState(false)
  const closeModalIncorrectPIn=()=>{
    setShowModalIncorrectPIn(false)
    setShowModal(true)
  }
  const handleCloseModal = () => {
    setShowModal(false);   
    setTimeout(() => {
      window.location.reload();
    }, 20000);   
  }
  const [city, setCity] = useState([]);
  const [area,setArea]=useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [address, setAddress] = useState('');
  const [restaurantListData, setRestaurantListData] = useState([]);
  const [pincode, setPincode ] = useState(''); 
  const [error,setError]=useState('')


const selectRest = async () => {
  if(pincode && pincode.length === 6){
    sessionStorage.setItem('CURRENT_USER_PINCODE',pincode);
    const item = await findRestaurantPetpoojaIdByPincode(pincode);
    if(item?.latitude && item?.latitude){
      const data = {
        latitude: item.latitude,
        longitude: item.longitude,
      };
      setSessionForModal("longitude", data);
      setLatitude(item.latitude);
      setLongitude(item.longitude);
      handleCloseModal();
    } else{
      // navigate('/merchandise')
      // setShowModalIncorrectPIn(true)
      // setShowModal(false)
      setError('Please enter valid Pin Code')
      // alert('Please enter valid Pin Code');
    }
  }else{
    // navigate('/merchandise')
    // setShowModalIncorrectPIn(true)
    // setShowModal(false)
    setError('Please enter valid Pin Code')
    // alert('Please enter valid Pin Code');
  }
}
/************ Handle Pincode Change ************ */
const handlePinChange = (e) => {
  const value = e.target.value;

  // Allow input only if it contains 6 digits
  if (value.length <= 6) {
    setPincode(value);

    // Check if the pincode is exactly 6 digits
    if (value.length === 6) {
      setError('');
    } else {
      setError('Pincode must be exactly 6 digits.');
    }
  } else {
    // Prevent further input if pincode exceeds 6 digits
    setError('Pincode cannot be longer than 6 digits.');
  }
};

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const data = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          // setSessionForModal("longitude", data);
          // setLatitude(latitude);
          // setLongitude(longitude);
          
          try {
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAF03PgdixMQA7kXCPQhjubzZ39r5pz224`
            );
            const results = response?.data?.results;
            if (results.length > 0) {
              sessionStorage.setItem('CURRENT_ADDRESS',results[0].formatted_address)
              setAddress(results[0].formatted_address);

              const addressComponents = results[0].address_components;
              for (const component of addressComponents) {
                if (component.types.includes('postal_code')) {
                  sessionStorage.setItem('CURRENT_USER_PINCODE',component.long_name)
                }
              }

            }
          } catch (error) {
            console.error('Error getting address:', error.message);
          }
          setSessionForModal("longitude", data);
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.error('Error getting location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };



  const requestLocationPermission = () => {
    if ('geolocation' in navigator) {
      navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
        if (permissionStatus.state === 'prompt') {
          // Permission status is 'prompt', so we can request the position
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              // Do something with the position if needed
              const data = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
   
              };
              //Get Address
              try {
                const response = await axios.get(
                  `https://maps.googleapis.com/maps/api/geocode/json?latlng=${data?.latitude},${data?.longitude}&key=AIzaSyAF03PgdixMQA7kXCPQhjubzZ39r5pz224`
                );
                const results = response?.data?.results;
                if (results.length > 0) {
                  sessionStorage.setItem('CURRENT_ADDRESS',results[0].formatted_address)
                  setAddress(results[0].formatted_address);
    
                  const addressComponents = results[0].address_components;
                  for (const component of addressComponents) {
                    if (component.types.includes('postal_code')) {
                      sessionStorage.setItem('CURRENT_USER_PINCODE',component.long_name)
                    }
                  }
    
                }
              } catch (error) {
                console.error('Error getting address:', error.message);
              }
              setSessionForModal("longitude", data);
              setLatitude(position.coords.latitude);
              setLongitude(position.coords.longitude);
            },
            (error) => {
              console.log('Error getting location:', error.message);
            }
          );
        } else if (permissionStatus.state === 'granted') {
          // Permission status is 'granted', meaning the user already allowed access
          getLocation()
        } else if (permissionStatus.state === 'denied') {
          // Permission status is 'denied', meaning the user denied access
          setLatitude(19.13603104909766);
          setLongitude(72.83304440335617);
          const data = {
            latitude: 19.13603104909766,
            longitude: 72.83304440335617,

          };
         setSessionForModal("longitude", data);
          // latitude = 19.13603104909766;
          // longitude = 72.83304440335617;
          // console.log('Location access denied by the user.');
        }
      });
    }
  }; 
   useEffect(() => {
    // Check if latitude is set and trigger reload
    if (latitude !== null) {
      window.location.reload();  
    }
  }, [latitude]);
  
  const handleSubmit =  (e) => {
    e.preventDefault();
    requestLocationPermission()
    handleCloseModal();
    };
    // console.log('restaurantListData',restaurantListData)

    const closePincodePopup = () => {
      navigate('/');
    }

  return (
    <>
<Modal show={showModal} onHide={closePincodePopup} centered backdrop="static" >
  {/* <Modal.Header closeButton>
    <Modal.Title style={{ fontSize: "16px", textAlign: "center" }}>
      Select Your Location
    </Modal.Title>
  </Modal.Header> */}
  <Modal.Body style={{ padding: "20px" }} className="Mob_Otp_popup">
    <img 
      src={process.env.PUBLIC_URL + '/images/icons/cross.png'} 
      alt="cross" 
      onClick={closePincodePopup} 
      style={{ cursor: "pointer", float: "right", marginBottom: "10px" }} 
    />
    <div className="select-city" style={{ textAlign: "center" }}>
      <p style={{ marginBottom: "20px" }}>
        Pick your closest Doolally. Operational 7 days a week.
        <br />
        Delivery times: 11am to 1:30am
      </p>

      {/* Add other form fields here */}
      <button 
        id="nearest-btn" 
        onClick={handleSubmit} 
        style={{
          width: "100%",
          padding: "10px",
          margin: "10px 0",
          backgroundColor: "#a2c760",
          color: "#fff",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "14px"
        }}>
        <i className="fa fa-map-marker" style={{paddingRight:"4px",paddingTop:"3px"}}></i> Auto Select Nearest
      </button>
      
      <h6 style={{ margin: "15px 0 10px 0", fontSize: "14px" }}>OR</h6>

      <div className="form-group" style={{ display: "flex", alignItems: "center",paddingBottom:"1.2%" }}>
  <input 
    className="CreateEventForm_formField__G1FTy" 
    id="inputNearser" 
    type='number' 
    onChange={handlePinChange} 
    name='pinCode' 
    value={pincode} 
    placeholder="Enter your current pincode" 
    style={{
      flex: "1",
      padding: "10px",
      marginRight: "10px",
      borderRadius: "4px",
      border: "1px solid #ced4da",
      fontSize: "14px",
      height: "40px",
      boxSizing: "border-box"
    }}
  />
  <div 
    // className="check-btn-pin" 
    onClick={selectRest} 
    style={{
      padding: "10px 20px",
      backgroundColor: "#a2c760",
      color: "#fff",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "14px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box"
    }}>
    Check
  </div>
</div>
{error && <p className='text-left' style={{ color: 'red' ,lineHeight:"0",fontSize:"13px", paddingTop:"2%",textAlign:"left"}}>{error}</p>}
    </div>
  </Modal.Body>
</Modal>

<Modal show={showModalIncorrectPIn} onHide={closeModalIncorrectPIn} centered backdrop="static" size="sm" >
<Modal.Header closeButton>
  <h5>Wrong Pin Code !</h5>
</Modal.Header>
<Modal.Body style={{paddingBottom:"0px"}}>
<h6 className="pb-3">Please enter a valid Pin Code</h6>
</Modal.Body>
</Modal>
</>
  );
};

export default ModalComponent;
