import React, { useEffect, useState, useContext } from "react";
import Header from "../components/HeaderMob";
import Footer from "../components/FooterMob";
import Modal from "react-bootstrap/Modal";
import { Link , useNavigate } from "react-router-dom";
import ModalComponent from "../components/FnbModalCheck";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import FnbContext from "../contextApi/FnbContext";
import Search from "../components/search";

const Merchandise = () => {

  // serachbar
  const placeholders = [
    "Discover Workshops",
    "Order Fries",
    "Order Dips",
    "Order Apple Cider",
    "Buy Craft beer",
    "Buy Coasters",
    "Try Chicken Ghee Roast",

  ];
  const [counter, setCounter] = useState(0);
  const [placeholder, setPlaceholder] = useState(placeholders[counter]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter + 1) % placeholders.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [counter]);

  useEffect(() => {
    setPlaceholder(placeholders[counter]);
  }, [counter]);

  // end

  const { merchandiseCategory , cartItems,handleChangeIncDec,merchandiseItem, onAdd, addon,soldOutItems } =
    useContext(FnbContext);
    const navigate = useNavigate();

  const [selectedAddons, setSelectedAddons] = useState(null);
  const [extraItem, setExtraItem] = useState([]);
  const [temp, setTemp] = useState([]);
  const [buyNow,setBuyNow] = useState();
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [merchandiseItems, setMerchandiseItem] = useState(merchandiseItem);

  const handleAdd = (item, index, e) => {

    if (item.addon && item.addon.length > 0) {
      // the addon array is not empty
      for (let i = 0; i < addon.length; i++) {
        if (item.addon[0].addon_group_id === addon[i].addongroupid) {
          setExtraItem([addon[i]]);
          setTemp(item);
          setBuyNow(e)                                                                               
          handleShow2();
          break; 
        }
      }
    } else {
      onAdd(item, index); 
      if(e.target.name==="buy_now"){
        navigate('/cart')
      }                                            
    }
  };
  const onUpdateField = (e, addonItem) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedAddons(addonItem);
    } else {
      setSelectedAddons(
        selectedAddons.filter(null )
      );
    }
  };

  const getSearchData = async (searchKey) => {

    let data = [];
    merchandiseItem.forEach((element) => {
        let e;
        if (element?.itemname?.includes(searchKey)) {
           e = element;
        }
        if(e!=null && e?.itemname!=null){
            data.push(e);
            setMerchandiseItem(data);
        }
    });
  }

  return (
    <>
           <ModalComponent /> 
      <div className="d_margin container-fluid">
        <Header />
<Search/>

        <div className=" ds-none  home-1st-col d-flex justify-content-around poppins  mt-2 ">
          <p>
            <Link to="/beer">
              <img
                src={process.env.PUBLIC_URL + "./img/Bevrages_icon.png"}
                className="h-1st-col"
              />
              <br />
              Beverages
            </Link>
          </p>
          <p>
            <Link to="/food">
              <img
                src={process.env.PUBLIC_URL + "./img/Food_icon.png"}
                className="h-1st-col"
              />
              <br />
              Food
            </Link>
          </p>
          <p id="circle-effect">
            <img
              src={process.env.PUBLIC_URL + "/images/merch.png"}
              className="h-1st-col"
            />
            <br />
            Merchandise
          </p>
        </div>

        {/* body */}
        {/* <div className="d_main_title mt-4" style={{paddingLeft:"3%"}}>  Recommendation and best sellers
<p  className="d_main_sub_title"> Crowd favourites , post hits, latest pics</p></div>
<FoodOrderBanner/> */}
     <div style={{ marginBottom: "80px" }}>
  <div className="row g-2 mt-3">
    {merchandiseItems?.map((item, index) => (
      <div className="col-6 col-md-6 col-lg-4" key={index}>
        <div className="d_featured_events_box">
          <figure className="mb-0">
            <img
              src={item.item_image_url || `${process.env.PUBLIC_URL}/images/tshirt2.jpg`}
              alt=""
              style={{ height: "200px", width: "100%", objectFit: "cover" }}
            />
          </figure>
          <div className="d_feat_event_cnt poppins">
            <ul className="d_feat_event_list" style={{ marginTop: "20px" }}>
              <li style={{ height: "30px", fontWeight: "bold" ,paddingTop:"10px" }}>
                {item.itemname}
              </li>
              <li style={{ height: "44px",lineHeight:"15px",paddingTop:"15px" }}>
                {item.itemdescription.slice(0, 25)}...
              </li>
              <li style={{ fontWeight: "bold" }}>
                Rs {item.price}/-
              </li>
            </ul>
          </div>
          <div className="d_feat_event_btn">
            <a className="d_reminder" href="#">
              Remind Me Later
            </a>
            {soldOutItems.includes(item.itemid) ? (
              <button
                type="button"
                className="d_book_event poppins"
                style={{
                  backgroundColor: "#edf7dd",
                  border: "none",
                  marginLeft: "20%",
                  color: "red",
                  borderRadius: "5px",
                  cursor: "not-allowed",
                }}
                disabled
              >
                <strong>Sold Out</strong>
              </button>
            ) : (
              <>
                {cartItems.find((cartItem) => cartItem.itemid === item.itemid) ? (
                  <div className="d_book_event quantity-container2" style={{ margin: "5% 0", width: "90%", height: "24px",marginLeft:"3%" }}>
                    <button
                      className="quantity-btn"
                      onClick={() => handleChangeIncDec(item, -1)}
                      style={{paddingLeft:"3%"}}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      value={cartItems.find((cartItem) => cartItem.itemid === item.itemid).qty}
                      readOnly
                      className="quantity-input"
                    />
                    <button
                      className="quantity-btn"
                      onClick={() => handleChangeIncDec(item, 1)}
                      style={{paddingRight:"3%"}}
                    >
                      +
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="d_book_event"
                    style={{ margin: "5% 0", width: "90%",marginLeft:"3%" }}
                    onClick={(e) => handleAdd(item, index, e)}
                  >
                    Add
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
</div>

        <Footer />
      </div>

      {/* modal */}

      <Modal
          show={show2}
          onHide={handleClose2}
          aria-labelledby="contained-modal-title-bottom"
        >
          <Modal.Header closeButton>
            <h5>{temp.itemname}</h5>

            {/* <Button onClick={handleClose2} id="btn-modal-footer" style={{backgroundColor:"#a2c760" , width:"75px"}}>Add</Button> */}
            <br />
          </Modal.Header>
          <h6
            style={{
              marginBottom: "-10px",
              paddingTop: "17px",
              marginLeft: "2%",
            }}
          >
           Select your size.
          </h6>
         
          <Modal.Body>
            <div
              className="row"
              style={{ overflow: "scroll", height: "400px" }}
            >
              <div className="row">

                {extraItem.map((addonGroup, index) => (
                  <div key={index}>
                    <p className="d_main_title d_box">
                      {addonGroup.addongroup_name}
                    </p>
                    <h6>you can choose any one option </h6>
                    {addonGroup.addongroupitems.map((addonItem, index) => (
                      <div className="row" key={index}>
                        <div className="col">
                          <div class=" custom-control " style={{width:"100%"}}>
                            <input
                              type="checkbox"
                              value={addonItem}
                              name="size-check"
                              checked={selectedAddons === addonItem}
                              onChange={(e) => {
                                onUpdateField(e, addonItem);
                              }}
                            />
                            <label class="custom-control-labl" style={{marginRight:"5%"}}>
                              {addonItem.addonitem_name}
                            </label>
                            <span class=" adonPrice">
                              Rs {addonItem.addonitem_price}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}

                {/* <br />
                <p className="d_main_title d_box">T-shirt type</p>
                <h6>you can choose any one option </h6>
                <div className="row">
                  <div className="col">
                    <div class=" custom-control ">
                      <input type="checkbox" />
                      <label class="custom-control-label">Men</label>
                      <span class=" adonPrice">Rs 0.00</span>
                    </div>
                  </div>
                  <div className="col mb-4">
                    <div class=" custom-control ">
                      <input type="checkbox" />
                      <label class="custom-control-label">Women</label>
                      <span class=" adonPrice">Rs 0.00</span>
                    </div>
                  </div>
                  <div style={{ borderBottom: "double" }}></div>
                </div> */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              variant="secondary"
              onClick={() => {
                onAdd(temp, selectedAddons);
                if(buyNow && buyNow.target.name==="buy_now"){
                  navigate('/cart');   }
                handleClose2();
              }}
              className="add-on-more-btn"
            >
              Add
            </button>
          </Modal.Footer>
        </Modal>
    </>
  );
};

export default Merchandise;
