import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import { apiBaseUrl } from "../config/constant";

const Review = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const googleReviews = async () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          type: "beer"
        })
      };
      const response = await fetch(
        apiBaseUrl+"common/get-google-reviews",
        requestOptions
      );
      const data = await response.json();
      setReviews(data.response);
    };
    googleReviews();
  }, []);

  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1.5,
    // slidesToScroll: 0.5,
    rtl: false,
    cssEase: "linear",
    arrows: false,
    initialSlide: 1.5
  };

  return (
    <div>
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <div key={index}>
            <div className="review_mobile_view poppins">
              <p
                style={{
                  padding: "5%",
                  color: "# 5b5b5b",
                  opacity: "100%",
                  height: "146px",
                  fontSize:"13px",
                  overflow:"scroll",
                  margin:"0px"
                  // wordWrap: "break-word"
                }}
              >
                {review.content}
              </p>
              <div
                className="d-flex justify-content-between"
              >
                <p
                  href="#"
                  style={{
                    fontStyle: "italic",
                    fontWeight: "600",
                    color: "black",
                    fontSize: "12.1px",
                    padding:"8px 0px 0px 12px"
                  }}
                >
                  {review.name.substring(0, 8)}

  
                </p>

                <span>
              <img
                  src={process.env.PUBLIC_URL + "/images/icons/quotation.png"}
                  style={{
                    width: "20px",
           
                  }}
                />
                  </span>
                <img
                  className="review_pic"
                  src={process.env.PUBLIC_URL + "/images/icons/user2.jpg"}
                  alt="Card image cap"
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Review;
