import React,{useState,useEffect} from "react";
import Header from "../components/HeaderMob";
import Review from "../Sliders/Review";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/FooterMob";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { apiBaseUrl } from "../config/constant";
import Search from "../components/search";

const Location = () => {

   // serachbar
   const placeholders = [
    "Discover Workshops",
    "Order Fries",
    "Order Dips",
    "Order Apple Cider",
    "Buy Craft beer",
    "Buy Coasters",
    "Try Chicken Ghee Roast",

  ];
  const [counter, setCounter] = useState(0);
  const [placeholder, setPlaceholder] = useState(placeholders[counter]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter + 1) % placeholders.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [counter]);

  useEffect(() => {
    setPlaceholder(placeholders[counter]);
  }, [counter]);

  // end
  const [show, setShow] = useState(false);
  const [LocationData,setLocationData] = useState([]);
  const handleClose = () => setShow(false);
  const [selectedLocation,setSelectedLocation] = useState('Mumbai');
  // const handleShow = () => setShow(true);

  // -----------------------------------------------------------api calls--------------------------------------------------------
  const getLocationPageData = async (desiredState) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}common/get-location-page-data`,
        {
          params: {
            state: desiredState,
          },
        }
      );
      // console.log('Response:', response.data);
        const filteredData = response.data.response.result.filter(item => item.state === desiredState);
        setLocationData(filteredData )
        setSelectedLocation(desiredState);
    } catch (error) {
      // Handle errors here
      console.error('Error:', error);
    }
  };
useEffect(()=>{
  // Call the function to fetch data
  getLocationPageData('Mumbai');
  
},[])



// -----------------------------------------------------------end api calls--------------------------------------------------------


  return (
    <>
      <div className="d_margin mb-4 pb-4 container-fluid">
        <Header />

<Search/>
        <div
          className=" ds-none  home-1st-col d-flex justify-content-center poppins  mt-2 "
          style={{ margin: "1px auto" }}
        >
          <p id={selectedLocation==='Mumbai'?'circle-effect':''} onClick={()=>{getLocationPageData('Mumbai')}}>
            {" "}
            <img
              src={process.env.PUBLIC_URL + "/images/mumbai.png"}
              className="h-1st-col"
              alt="mumbai"
            />{" "}
            <br />
            Mumbai
          </p>

          <p id={selectedLocation==='Pune'?'circle-effect':''} onClick={()=>{getLocationPageData('Pune')}}>
            {" "}
            <img
              src={process.env.PUBLIC_URL + "/images/pune.png"}
              className="h-1st-col"
              alt="pune"
            />{" "}
            <br />
            Pune
          </p>
          {/* <p> <img src={process.env.PUBLIC_URL + "/images/pune.png"} className="h-1st-col"/><br/>Pune</p> */}
        </div>

        <div className="ds-none d_main_panel_review mt-4">
          <div className="d_right_panl box_paddng">
            <div className="d_title_box">
              <h3 className="d_main_title">What others have to say</h3>
              <p className="d_main_sub_title">
                Beer reviews, food ratings experiences{" "}
                <span className="d_line"></span>
                <span className="d_round"></span>
                <span className="d_round"></span>
                <span className="d_round"></span>
              </p>

              <Review />
            </div>
           
          </div>
        </div>
        {/* body */}


        {LocationData.map((location,index)=>(
          <div
            className="d-flex justify-content-between"
            style={{
              border: "1px solid #d4d4d4",
              borderRadius: "5px",
              boxShadow: "2px 5px 5px 5px #f5f5f5",
              marginBottom: "2%",
              padding: "2% 2% 0 2%",
            }}
          >
         <div className="">
           <h5 className="poppins"> {location.taproom}</h5>
           <img
             src={
               process.env.PUBLIC_URL + "/images/icons/star.jpg"
             }
             style={{
               width: "60px",
               margin: "0px auto",
               marginTop: "-20px",
             }}
           />
           <p
             style={{
               fontSize: "14px",
               color: "grey",
               width: "200px",
             }}
           >
            <span dangerouslySetInnerHTML={ { __html: location.address}}></span>
            <br/>
            <FontAwesomeIcon icon={faPhone} beat style={{color: "#88ad00", margin: "0px"}} /> 
            <a href={`tel:+91${location.phone}`} style={{margin: "5px"}}>
              +91 {location.phone}
            </a>
           </p>
         </div>

         <div className=" text-center">
         <a href={location.mapLink} target="_blank">
             <FontAwesomeIcon
             icon={faLocationDot}
             beat
             style={{
               position: "absolute",
               color: "#9BC059",
               fontSize: "30px",
               marginLeft: "5.6%",
             }}
           />
           
           <img
             src={process.env.PUBLIC_URL + "/images/map.png"}
             style={{ width: "120px", height: "125px" }}
           />
           </a>

           <br />

           <strong>
        <br />
             {location.taproom}
           </strong>
           <p style={{ fontSize: "14px", color: "grey" }}>
           {location.timing}
           </p>
         </div>
         
       </div>
            ))}
    

        <Footer />
      </div>

        {/* modal opens for pune  */}
        <Modal show={show} onHide={handleClose}   centered>
        <Modal.Header closeButton>
        <Modal.Title>Pune Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>Soon coming to your neighbourhood Adda!!!!</Modal.Body>
    
      </Modal>
      {/* end popup open pune */}
    </>
  );
};

export default Location;
