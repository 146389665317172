import React, { useState, useEffect } from "react";

import { apiBaseUrl } from "../config/constant";
import axios from "axios";

const TermNCondition = () => {

  const [pageData, setPageData] = useState([]);
    const getData = () =>{
        const apiUrl = apiBaseUrl + "common/get-cms-data";

        axios.post(apiUrl,{
            page : "Event Terms and Conditions"
        })
        .then((response) => {
       
            setPageData(response.data.response.result);

        })
        .catch((error) => {
            
            console.error("API error:", error);
        });
    }
     useEffect(() => {
        getData();
  }, []); 

  return (
    <>
      <div className="modal-body pt-0 monster">
        <div
          className="content-block"
          style={{ height: "300px", overflow: "auto" }}
        >
          <h5>
            <strong>General rules:</strong>
          </h5> 
          <div dangerouslySetInnerHTML={ { __html: pageData?.content}}>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermNCondition;
