import React, { useEffect, useState, useContext } from "react";
import "../style_web/styleDoolally.css";
import Review from "../Sliders/Review";
import Header from "../components/HeaderWeb";
import Footer from "../components/FooterWeb";
import Helper from "../utils/Helper";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import FnbContext from "../contextApi/FnbContext";
import ModalComponent from "../components/FnbModalCheck";
import {
  getRequestOptions,
  apiBaseUrl,
  homePageApiUrl,
  fBDImageBaseUrl,
  eventImageBaseUrl,
} from "../config/constant";
import {
  setUserCurrLoc,
  getUserCurrLoc,
  getUser,

} from "../utils/UserAuthenticate";


const Food = () => {
  const {
    foodCategory,
    foodItem,
    onAdd,
    addon,
    handleChangeIncDec,
    cartItems,
    resturantDetails,
    soldOutItems
  } = useContext(FnbContext);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [extraItem, setExtraItem] = useState([]);
  const [temp, setTemp] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
const[NameItem,setNameItem]=useState('Breakfast')
  const [twitterView, settwitterView] = useState([]);
  const [whatsOnTap, setwhatsOnTap] = useState([]);
  const [location, setLocation] = useState([]);
  const [hangout, setHangout] = useState([]);
  const [whatHappingWeek, setwhatHappingWeek] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [isloading, setIsloading] = useState(false);

  

  useEffect(() => {
    Helper.checkCurrentUserLegalAge().then((res) => {
      if (res.legalAge === "No") {
        window.location = "/homepage";
      }
    });
    if (!getUserCurrLoc()) {
      setUserCurrLoc();
    }
    getData();
    //removeUserSession();
  }, []);

  const getData = async () => {
    try {
      setIsloading(true);
      // here Api call for Home page
      let result = await fetch(apiBaseUrl + homePageApiUrl, getRequestOptions);
      if (result) {
        result = await result.json();
        settwitterView(result.response.result.twitterView);
        setwhatsOnTap(result.response.result.whatsOnTap);
        setLocation(result.response.result.location);
        setHangout(result.response.result.hangout);
        setwhatHappingWeek(result.response.result.whatHappingWeek);
        setTestimonial(result.response.result.testimobial);
        setTimeout(() => {
          setIsloading(false);
        }, 500);
        // console.log(result.response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleAdd = (item, index) => {
    if (item.addon && item.addon.length > 0) {
      // the addon array is not empty
      for (let i = 0; i < addon.length; i++) {
        if (item.addon[0].addon_group_id === addon[i].addongroupid) {
          setExtraItem([addon[i]]);
          setTemp(item);
          handleShow2();
          break; // stop looping once the desired data is found
        }
      }
    } else {
      // the addon array is empty
      onAdd(item,index)
      setSelectedAddons([])
      // handleChangeIncDec(item, 1);
    }
  };

  const [selectedAddons, setSelectedAddons] = useState([]);

  // const onUpdateField = addonItem => {
  //   if (selectedAddons === addonItem) {
  //     // If the addon is already selected, unselect it on double-click
  //     setSelectedAddons(null)
  //   } else {
  //     // Otherwise, select the addon
  //     setSelectedAddons(addonItem)
  //   }
  // }

  const onUpdateField = (addonItem) => {
    if (selectedAddons.includes(addonItem)) {
      setSelectedAddons(selectedAddons.filter(item => item !== addonItem));
    } else {
      setSelectedAddons([...selectedAddons, addonItem]);
    }
  };
  
  


  // paggination
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(3);


  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  console.log(selectedFilter,"selectedfilet")
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredItems = selectedFilter
    ? foodItem?.filter((item) => item.item_categoryid === selectedFilter)
    : foodItem;
  const totalPages = Math.ceil(filteredItems?.length / itemsPerPage);
  // const foodItems = filteredItems?.slice(indexOfFirstItem, indexOfLastItem);
  const foodItems= filteredItems
// end paggination

// veg non veg filtration
const [selectedOption, setSelectedOption] = useState(null);

const VegProduct = "veg";
const NonVegProduct = "nonveg";

const VegProductId={
  categoryid:"69924"
}

const NonVegProductId={
  categoryid:"69925"
}

const handleFilterClick2 = (option,item) => {
  if (option === selectedOption) {
    setSelectedOption(null); // clear selected option if clicked again
  } else {
    setSelectedOption(option); // set selected option
  }
  handleFilterClick(item)
};


// //endveg non veg filtration


// start filataration
const handleFilterClick = (item) => {
  setNameItem(item.categoryname)
  setSelectedFilter((prevFilter) => {
    // If the same category is clicked again, clear the filter
    if (prevFilter === item.categoryid) {
      return null;
    } else {
      return item.categoryid;
    }
  });
};
// end filtration



  return (
    <>
      <ModalComponent />
      <div className="container-fluid">
  
        <Header />
        <section className="d_main_panel" style={{ margin: "0 1%" }}>
          <div className="container-fluid">
            <div className="row g-5">
              <div className="col-lg-3 mb-4">
                <div className="box_padding gray_bg_color" style={{height:"842px", overflow:"scroll"}}>
                  <div className="d_title_box">
                    <h5 className="d_main_title">What's On Tap </h5>
                    <p className="d_main_sub_title">
                    Citrusy Witbier, Dark Decadent Stouts, Fruity Cider
                      <span className="d_line"></span>
                      <span className="d_round"></span>
                      <span className="d_round"></span>
                      <span className="d_round"></span>
                    </p>
                  </div>
                  <div className="row gy-2 gx-4">
                    {/* here the Api render on home page for whats on tap */}
                    {whatsOnTap.slice(0, 8).map((item, index) => (
                      <div className="col-6" id="whatsonTap">
                      <Link to="/beer">
                        <div className="d_product_box">
                          <figure className="d_product_img mb-0" key={index}>
                            <img
                              src={fBDImageBaseUrl + "thumb/" + item.filename}
                              alt=""
                            />
                          </figure>
                          <p className="d_product_title">{item.itemName}</p>
                          <a className="d_overlay">
                            <p>Explore More</p>
                          </a>
                        </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-4 ">
                <div className=" d_midile_panal gray_bg_color" style={{ height: '192px', marginBottom: '35px' }}>
                  {/* <Location/> */}
                  {/* <h3 className="d_main_title box_padding">Our Locations</h3> */}
                  <div className="location-section ">
                    <div className="d-flex justify-content-around pt-3">
                      <div className="adj-location">
                        <Link to="/beer">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "./img/Bevrages_icon.png"
                            }
                          />
                          <h5> Beverages</h5>
                        </Link>
                      </div>
                      <div className="adj-location" id="circle-effect">
                        <Link to="/food">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "./img/Food_icon.png"
                            }
                          />
                          <h5> Food </h5>
                        </Link>
                      </div>
                      <div className="adj-location">
                        <Link to="/merchandise">
                          <img
                            src={
                              process.env.PUBLIC_URL + "./images/merch.png"
                            }
                          />
                          <h5> Merchandise</h5>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="d_food box_padding gray_bg_color"
                  style={{height:"634px"}}
                >
                  <div className="h-food-menu d-flex justify-content-between" >
                    <div>
                      <p className=" d_main_title pd-2 ">
                        <h4>Menu</h4>{" "}
                        <h6 className="d_main_sub_title">{NameItem}</h6>{" "} 
                      </p>
                    </div>

                    <div >
                    <div className="poppins d-flex  ">

                      <label class="switch mt-2 " >
                        <input
                          type="checkbox"
                  
                          checked={selectedOption === VegProduct} // add checked prop based on selected option
                          onClick={() => { handleFilterClick2(VegProduct,VegProductId)}}
                        />
                        <span class="slider round"></span>
                      </label>
                      <span className="mt-1" style={{ paddingLeft: "3px" }} >
                        {" "}
                        Veg
                      </span>
                      <div className=" ms-2 d-flex" id="n-veg">
                        <label class="switch mt-2  ">
                          <input type="checkbox"  
                          checked={selectedOption === NonVegProduct} // add checked prop based on selected option
                           onClick={() => handleFilterClick2(NonVegProduct,NonVegProductId)}/>
                          <span class="slider round"></span>
                        </label>
                        <span className="m-1" style={{ paddingLeft: "3px" }}>
                          Non-Veg
                        </span>
                      </div>
                                            
                    </div>
                   
                   
                   
                      {resturantDetails?<div className="location_resturant  d-flex flex-column" id="food-web_loc"><p><FontAwesomeIcon icon={faLocationDot}/>{resturantDetails.restaurantName}</p></div> :""}    
                    </div>
                  </div>
              <div className="food_items" style={{height:"500px"}}  >
              {/* foodItem */}
              {foodItems ? (
                    foodItems.map((item, id) => (
<div
  key={id}
  className="food-menu-container"
>
  <div className="food-details">
    <h5>{item.itemname}</h5>
    <p className="description">{item.itemdescription}</p>
    <h6>{item.price}</h6>
  </div>

  <div className="image-container">
    <img
      src={item.item_image_url ? item.item_image_url : process.env.PUBLIC_URL + "/images/pro7.png"}
      alt=""
    />
    <br />
    {soldOutItems.includes(item.itemid) ? (
      <div className="sold-out">Sold Out</div>
    ) : cartItems.find((cartItem) => cartItem.itemid === item.itemid) ? (
      <div className="quantity-container">
        <span onClick={() => handleChangeIncDec(item, -1)}>-</span>
        <input
          type="number"
          value={cartItems.find((cartItem) => cartItem.itemid === item.itemid).qty}
          readOnly
        />
        <span onClick={() => handleChangeIncDec(item, 1)}>+</span>
      </div>
    ) : (
      <button onClick={() => handleAdd(item, id)}>Add</button>
    )}
  </div>
</div>

))

                  
                  ) : (
                    <LoadingSpinner />
                  )}
              </div>
             
                  {/* <div className="paggination-btn">
                    <button onClick={handleNextPage}>Next</button>
                    <button onClick={handlePrevPage}>Prev</button>

                    <p>
                      Page {currentPage} of {totalPages}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-3">
                <div className="d_right_panal box_padding" style={{height:"500px"}}>
                  <div className="d_title_box">
                    <h5 className="d_main_title">Categories</h5>
                    {/* <p className="d_main_sub_title">Day Plan 
<span className="d_line"></span>
<span className="d_round"></span>
<span className="d_round"></span>
<span className="d_round"></span>
</p> */}
                  </div>

                  <ul className="catgry-beer">
                    {/* <li className="d-flex">
                      <img
                        src={process.env.PUBLIC_URL + "/images/starter.jpg"}
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          marginLeft: "-24px",
                          padding: "2%",
                        }}
                      />
                      Starters
                    </li> */}
                    {foodCategory?.map((item, index) => (
                      <li
                        key={item.categoryid}
                        id="fnb-category"
                        className={
                          selectedFilter === item.categoryid ? "active" : ""
                        }
                        onClick={() => handleFilterClick(item)}
                      >
                        {item.categoryname}
                      </li>
                    ))}
                    {/* <div className="paggination-btn">
<button onClick={handleNextPage}>Next</button>
<button onClick={handlePrevPage}>Prev</button>
     
      <p>Page {currentPage} of {totalPages}</p>
</div> */}
                    {/* <li>
                      <img
                        src={process.env.PUBLIC_URL + "/images/piza.jpg"}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          marginLeft: "-24px",
                          padding: "2%",
                        }}
                      />
                      Pizza
                    </li>
                    <li>
                      <img
                        src={process.env.PUBLIC_URL + "/images/burger.jpg"}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          marginLeft: "-24px",
                          padding: "2%",
                        }}
                      />
                      Burgers
                    </li>
                    <li>
                      <img
                        src={process.env.PUBLIC_URL + "/images/desert.jpg"}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          marginLeft: "-24px",
                          padding: "2%",
                        }}
                      />
                      Deserts
                    </li> */}
                  </ul>
                </div>
                <div className=" d_main_panel_review ">
                  <div className="d_right_panal box_padding" style={{ height: '327px' }}>
                    <div className="d_title_box">
                      <h5 className="d_main_title">What our customers say</h5>
                      <p className="d_main_sub_title">
                        What Our Happy Client Says
                        {/* <span className="d_line"></span>
                        <span className="d_round"></span> */}
                      </p>
                      <Review />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>

      <Modal
        show={show2}
        onHide={handleClose2}
        aria-labelledby="contained-modal-title-bottom"
      >
        <Modal.Header closeButton>
          <h5>{temp.itemname}</h5>

          {/* <Button onClick={handleClose2} id="btn-modal-footer" style={{backgroundColor:"#a2c760" , width:"75px"}}>Add</Button> */}
          <br />
        </Modal.Header>

        <Modal.Body>
        <div
  className="row"
  style={{ overflow: "scroll", height: "400px" }}
>
  {extraItem?.map((addonGroup, index) => (
    <div key={index}>
      <p className="d_main_title d_box">
        {addonGroup.addongroup_name}
      </p>
      <h6>You can choose multiple options</h6>
      {addonGroup?.addongroupitems?.map((addonItem, index) => (
        <div className="addOnItems_block" key={index}>
          <div>
            <input
              type="checkbox"
              value={addonItem.addonitem_name}
              checked={selectedAddons.includes(addonItem)} // Corrected: check if addonItem is in selectedAddons
              onChange={() => onUpdateField(addonItem)} // Handle change with onUpdateField
              className="Addoncustom-checkbox"
            />
            <label style={{ paddingLeft: "10px" }}>
              {addonItem.addonitem_name}
            </label>
          </div>

          <span>Rs {addonItem.addonitem_price}</span>
        </div>
      ))}
    </div>
  ))}
</div>


        </Modal.Body>
        <Modal.Footer>
          <button
            variant="secondary"
            onClick={() => {
              onAdd(temp, selectedAddons);
              setSelectedAddons([])
              handleClose2();
            }}
            className="add-on-more-btn"
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Food;
