import React from "react";
import "./spinner.css";

export default function LoadingSpinner() {
  return (
    <>
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
    {/* <div className="container gif" style={{textAlign:'center',paddingTop:123}}>
      <img src={process.env.PUBLIC_URL + "/images/beer.gif"} />
    </div> */}
    </>
  );
}