import React, { useState } from "react";
import Header from "../components/HeaderMob";
import Footer from "../components/FooterMob";
import axios from "axios";
import { apiBaseUrl } from "../config/constant";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const ContactUs = () => {
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({...errors,[e.target.name]:""})
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Form validation
    let errors = {};
    if (!formData.name) {
      errors.name = "Name is required";
    } else if (/\s/.test(formData.name)) {
      errors.name = "Name should not contain spaces";
    } else if (!/^[A-Za-z]+$/.test(formData.name)) {
      errors.name = "Name must contain only letters";
    } else if (formData.name.length < 4) {
      errors.name = "Name must be at least 4 characters long";
    }
    
    // Validate email
    if (!formData.email) {
      errors.email = "Email is required";
    }
    
    // Validate phone (10 digits, numbers only, no leading 0)
    if (!formData.phone) {
      errors.phone = "Phone is required";
    } else if (!/^[1-9]\d{9}$/.test(formData.phone)) {
      errors.phone = "Phone number must be 10 digits and cannot start with 0.";
    }
    
    // if (!formData.message) {
    //   errors.message = "Message is required";
    // }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      // POST request to the API endpoint
      await axios.post(apiBaseUrl + "common/contact-us", formData);
      handleShow()
    //   alert("Message sent successfully!");
      // Clear form data after successful submission
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Error sending message. Please try again later.");
    }
  };

  return (
    <div className="container-fluid">
      <div className="d_margin mb-4 pb-4">
        <Header />

        <section className="d_main_panel ">
          <div className="container-fluid">
            <div className="d_create_event d_right_panal box_padding black_heading">
              <div className="d_detail_heading hglight">Personal Details</div>
              <div className="d_create_event_form login_page">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-12 poppins">
                    <div className="d-flex contactImg " id="contactImg">
    <img
        src={process.env.PUBLIC_URL + "/images/icons/user.png"}
        alt="User Icon"
    />
    <input
        type="text"
        className="form-control p_gray"
        id="formGroupExampleInput"
        placeholder="Your name"
        style={{ borderColor: "null" }} // Fixed the style attribute name
        name="name"
        value={formData.name}
        onChange={handleChange}
    />
</div>
                      {errors.name && (
                        <span className="contact_us_eror">{errors.name}</span>
                      )}
                    </div>
                    <div className="col-12 poppins">
                      <div className="d-flex contactImg">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/smartphone-call.png"
                          }
                        />
                        <input
                          type="tel"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          className="form-control p_gray"
                          id="formGroupExampleInput"
                          placeholder="Phone number"
                        />
                      </div>
                      {errors.phone && (
                        <span className="contact_us_eror">{errors.phone}</span>
                      )}
                    </div>
                    <div className="col-12 poppins">
                      <div className="d-flex contactImg">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/envelope.png"
                          }
                        />
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          className="form-control p_gray"
                          id="formGroupExampleInput"
                          placeholder="Email id"
                        />
                      </div>
                      {errors.email && (
                        <span className="contact_us_eror">{errors.email}</span>
                      )}
                    </div>
                    <div className="col-12 poppins">
                      <div className="d-flex contactImg">
                        <textarea
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          className="form-control p_gray d_textarea"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Something about yourself (Optional)"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="d_comn_btn d-block">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <div className="desktop">
          <Footer />
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Message Sent!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Message Sent Successfully , Team Will Revert You Back!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
   
        </Modal.Footer>
      </Modal>
    </div>
    
  );
};

export default ContactUs;
