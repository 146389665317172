class Helper {
  // Return Promise
  static setCurrentUserLegalAge = () => {
    //localStorage.setItem("currentUserLegalAge","Yes");
    sessionStorage.setItem("currentUserLegalAge","Yes");
    return Promise.resolve({ legalAge: "Yes" });
  };

  static checkCurrentUserLegalAge = () => {
    //const currentUserLegalAge = localStorage.getItem("currentUserLegalAge");
    const currentUserLegalAge = sessionStorage.getItem("currentUserLegalAge");
    if(currentUserLegalAge){
      return Promise.resolve({ legalAge: "Yes" });
    } else {
      return Promise.resolve({ legalAge: "No" });
    }
  };

  static deleteCurrentUserLegalAge = () => {
    sessionStorage.removeItem("currentUserLegalAge","Yes");
    return Promise.resolve({ legalAge: "No" });
  };

  static setModalData = (key, data) => {
    sessionStorage.setItem(key, JSON.stringify(data));
  };

  static getModalData = () => {
    const data = sessionStorage.getItem("modalData");
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  static clearModalData = () => {
    sessionStorage.removeItem("modalData");
  };

  static checkModalData = () => {
    const data = Helper.getModalData();
    if (data && data.city) {
      return true;
    }
    return false;
  };
}
export default Helper;



// Set session data
export function setSessionForModal(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

// Get session data
export function getSessionForModal(key) {
  const value = sessionStorage.getItem(key);
  return value ? JSON.parse(value) : null;
}

// Set session for total amount
export function setSessionForAmount(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

// Get session for total amount
export function getSessionForAmount(key) {
  const value = sessionStorage.getItem(key);
  return value ? JSON.parse(value) : null;
}
// mobileno. verifyed 
export function setSessionForMobile(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function getSessionForMobile(key) {
  const value = sessionStorage.getItem(key);
  return value ? JSON.parse(value) : null;
}

