import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

const Header = (props) => {
  const { cartItems } = props;
  var y = cartItems;
  const hide = () => {
    var show = document.getElementById("share-lst").style.display;
    document
      .querySelector(".h-main")
      .firstElementChild.classList.replace("text-end", "text-start");
    //    console.log(s);
    if (show == "none") {
      document.getElementById("share-lst").style.display = "block";

      document
        .querySelector(".h-main")
        .firstElementChild.classList.replace("text-end", "text-start");
      document.getElementById("menu-bar").style.display = "none";
    } else {
      document.getElementById("share-lst").style.display = "none";

      document
        .querySelector(".h-main")
        .firstElementChild.classList.replace("text-start", "text-end");
      document.getElementById("menu-bar").style.display = "inline";
    }
  };


  return (
    <>
      <header className="row h-main">
        <div className="col-8 ds-none text-end">
          <Link to="/">
            <img
              src={process.env.PUBLIC_URL + "/images/splashLogo.png"}
              alt="logo"
              style={{ width: "6rem", padding: "7%" }}
            />{" "}
          </Link>
        </div>
        <div className="col h-left  ">
          {/* <img src={process.env.PUBLIC_URL + "/images/icons/new icons/instagram.png" }alt="logo"/>
    <img src={process.env.PUBLIC_URL + "/images/icons/new icons/twitter.png" }alt="logo"/>
    <img src={process.env.PUBLIC_URL + "/images/icons/new icons/facebook.png" }alt="logo"/>
    <img src={process.env.PUBLIC_URL + "/images/icons/new icons/whatsapp.png" }alt="logo"/>
     */}
          <div id="share-lst" style={{ display: "none" }}>
            <div
              className=" d-flex text-center"
              style={{ marginLeft: "-20px" }}
            >
              <Link to={`https://x.com/godoolally?t=IxX_9V1boooglDLZkaIcqQ&s=08`} target="_blank">
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/newTwittter.png"}
                  alt="logo"
                  style={{width:"21px"}}
                />{" "}
              </Link>
              <br />
              <Link to="https://www.facebook.com/godoolally/" target="_balnk">
                <img src={ process.env.PUBLIC_URL + "/images/icons/new icons/facebook.png" } alt="logo" />
              </Link>
              <br />
              <Link to="https://www.instagram.com/godoolally/?igsh=Mnhqenh5eHd3czNz" target="_blank" >
                {" "}
                <img src={ process.env.PUBLIC_URL + "/images/icons/new icons/instagram.png" } alt="logo" />
              </Link>

              {/* <Link to="https://wa.me/+919653188646">
                <img src={ process.env.PUBLIC_URL + "/images/icons/new icons/whatsapp.png" } alt="logo" style={{marginLeft:"6px"}} />
              </Link> */}
              <br />

              <CloseIcon onClick={hide} style={{ fontSize: "25px" }} />
            </div>
          </div>
          <MenuIcon id="menu-bar" onClick={hide} />
        </div>
        <div className="col-md-6 h-center  ph-none ">
          <img
            src={process.env.PUBLIC_URL + "/images/splashLogo.png"}
            alt="logo"
            style={{ width: "90px" }}
          />
        </div>
        <div
          className="col-1 col-md-3 h-right  ph-none"
          id="dirty_hedline_font"
        >
          <img
            src={
              process.env.PUBLIC_URL + "/images/icons/new icons/phone-call.png"
            }
            alt="logo"
          />{" "}
          022-48931314
        </div>
      </header>

    </>
  );
};

export default Header;
