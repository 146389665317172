import React, { useEffect, useState,useContext } from "react";
import "../style_web/styleDoolally.css";
import Review from "../Sliders/Review";
import Header from "../components/HeaderWeb";
import Footer from "../components/FooterWeb";
import Helper from "../utils/Helper";
import { Link , useNavigate } from "react-router-dom";
import TwitterIcon from "@material-ui/icons/Twitter";
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import FnbContext from "../contextApi/FnbContext";
import ModalComponent from "../components/FnbModalCheck";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import {
  getRequestOptions,
  apiBaseUrl,
  homePageApiUrl,
  fBDImageBaseUrl,
  eventImageBaseUrl,
} from "../config/constant";
import {
  setUserCurrLoc,
  getUserCurrLoc,
  getUser,
  getToken,
  removeUserSession,
} from "../utils/UserAuthenticate";
import { showCorrectImage, numberWithCommas } from "../utils/Common";

const Merchandise = () => {


  const { merchandiseCategory, merchandiseItem, onAdd, addon , handleChangeIncDec ,cartItems ,soldOutItems} =
    useContext(FnbContext);
    const navigate = useNavigate();
// console.log(merchandiseItem,"merchandisetems")

  const [twitterView, settwitterView] = useState([]);
  const [whatsOnTap, setwhatsOnTap] = useState([]);
  const [location, setLocation] = useState([]);
  const [hangout, setHangout] = useState([]);
  const [whatHappingWeek, setwhatHappingWeek] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState(null);
  const [extraItem, setExtraItem] = useState([]);
  const [temp, setTemp] = useState([]);
  const [buyNow,setBuyNow] = useState();
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);


  useEffect(() => {
    Helper.checkCurrentUserLegalAge().then((res) => {
      if (res.legalAge === "No") {
        window.location = "/homepage";
      }
    });
    if (!getUserCurrLoc()) {
      setUserCurrLoc();
    }
    getData();
    //removeUserSession();
  }, []);

  const getData = async () => {
    try {
      setIsloading(true);
      let result = await fetch(apiBaseUrl + homePageApiUrl, getRequestOptions);
      if (result) {
        result = await result.json();
        settwitterView(result.response.result.twitterView);
        setwhatsOnTap(result.response.result.whatsOnTap);
        setLocation(result.response.result.location);
        setHangout(result.response.result.hangout);
        setwhatHappingWeek(result.response.result.whatHappingWeek);
        setTestimonial(result.response.result.testimobial);
        setTimeout(() => {
          setIsloading(false);
        }, 500);

      }
    } catch (error) {
      console.log("error", error);
    }
  };


  const handleAdd = (item, index, e) => {

    if (item.addon && item.addon.length > 0) {
      // the addon array is not empty
      for (let i = 0; i < addon.length; i++) {
        if (item.addon[0].addon_group_id === addon[i].addongroupid) {
          setExtraItem([addon[i]]);
          setTemp(item);
          setBuyNow(e)                                                                               
          handleShow2();
          break; 
        }
      }
    } else {
      onAdd(item, index); 
      if(e.target.name==="buy_now"){
        navigate('/cart')
      }                                            
    }
  };
  const onUpdateField = (e, addonItem) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedAddons(addonItem);
    } else {
      setSelectedAddons(
        selectedAddons.filter(null )
      );
    }
  };


  return (
    <>
           <ModalComponent /> 
      <div className="container-fluid">
        <Header />
        <section className="d_main_panel" style={{ margin: "0 1%" }}>
          <div className="container-fluid">
            <div className="row g-5">
              <div className="col-lg-3 mb-4">
                <div className="d_left_pana box_padding gray_bg_color"       style={{ height: '842px', overflow: 'scroll' }}>
                  <div className="d_title_box">
                    {/* # Tag section left hand side  */}

                    {/* whats on tap left section */}
                    <h5 className="d_main_title">What's On Tap </h5>
                    <p className="d_main_sub_title">
                    {/* Citrusy Witbier, Dark Decadent Stouts, Fruity Cider */}
                    Order craft brews online, home delivery across Mumbai & Pune within 2 hours.
                      <span className="d_line"></span>
                      <span className="d_round"></span>
                      <span className="d_round"></span>
                      <span className="d_round"></span>
                    </p>
                  </div>
                  <div className="row gy-2 gx-4">
                    {/* here the Api render for whats on tap */}

                    {whatsOnTap?.slice(0, 8).map((item, index) => (
                      <div className="col-6" id="whatsonTap">
                      <Link to="/beer">
                        <div className="d_product_box">
                          <figure className="d_product_img mb-0" key={index}>
                            <img
                              src={fBDImageBaseUrl + "thumb/" + item.filename}
                              alt=""
                            />
                          </figure>
                          <p className="d_product_title">{item.itemName}</p>
                          <a className="d_overlay">
                            <p>Explore More</p>
                          </a>
                        </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* 3 circle icons section which is in  center of the first */}
              <div className="col-lg-6 mb-4 ">
                <div className=" d_midile_panal gray_bg_color" style={{ height: '192px', marginBottom: '32px' }}  >
                  <div className="location-section ">
                    <div className="d-flex justify-content-around pt-3">
                      <div className="adj-location">
                        <Link to="/beer">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                             "./img/Bevrages_icon.png"
                            }
                          />
                          <h5> Beverages</h5>
                        </Link>
                      </div>
                      <div className="adj-location">
                        <Link to="/food">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "./img/Food_icon.png"
                            }
                          />
                          <h5> Food </h5>
                        </Link>
                      </div>
                      <div className="adj-location" id="circle-effect">
                        <Link to="/merchandise">
                          <img
                            src={
                              process.env.PUBLIC_URL + "./images/merch.png"
                            }
                          />
                          <h5> Merchandise</h5>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  merchandise section where t-shirt and etc things render throung  APi */}
                <div
                  className="d_beer box_padding gray_bg_color  "
                  style={{ height: "634px"  }}
                >
                                 <div className="merch_items" style={{height:"610px",paddingBottom:"24px"}}>
                  <div className="row g-4">

                  {merchandiseItem?.map((item, index) => (
  <div className="col-6 col-md-6 col-lg-6 " key={index}>
    <div className="d_featured_events_box">
      <figure className="mb-0">
        <img
          src={item.item_image_url}
          alt=""
          style={{height:"200px"}}
        />
      </figure>
      <div className="d_feat_event_cnt" style={{ height: "100px" }}>
        <ul className="d_feat_event_list">
          <li>
            <strong>{item.itemname.slice(0, 20)} </strong>
          </li>
          <li> {item.itemdescription.slice(0, 20)}</li>
          <li className="d-flex justify-content-between" style={{ width: "55%" }}>
            <strong>Rs {item.price}/- </strong>
          </li>
        </ul>
      </div>
      <div className="d-flex justify-content-between">
  {soldOutItems.includes(item.itemid) ? (
    <div style={{ color: "red", margin: "0 0 5% 5%" }}>Sold Out</div>
  ) : (
    <>
      {cartItems.find((cartItem) => cartItem.itemid === item.itemid) ? (
        <div className="d_book_event quantity-container2" style={{ margin: "0 0 5% 9%", width:"79%" ,height:"25px", }}>
          <button className="quantity-btn" onClick={() => handleChangeIncDec(item, -1)}>-</button>
          <input
            type="number"
            value={cartItems.find((cartItem) => cartItem.itemid === item.itemid).qty}
            readOnly
            className="quantity-input"
          />
          <button className="quantity-btn" onClick={() => handleChangeIncDec(item, 1)}>+</button>
        </div>
      ) : (
        <button
          type="button"
          className="d_book_event"
          style={{ margin: "0 0 5% 9%", width:"79%" }}
          onClick={(e) => handleAdd(item, index, e)}
        >
          Add
        </button>
      )}
    </>
  )}
</div>

    </div>
  </div>
                  ))
}
                 

                    
                  </div>
                </div>
                </div>
              </div>

              {/*  caterioges section right hand side first */}
              <div className="col-lg-3">
                <div className="d_right_panal box_padding" style={{height:"505px", overflow:"scroll"}}>
                  <div className="d_title_box">
                    <h5 className="d_main_title">Categories</h5>
                  </div>
                  <ul className="catgry-beer">
                  {merchandiseCategory?.map((category,index)=>(
                    <li id="fnb-category">{category.categoryname}</li>

                  ))}
                  
                
                  </ul>
                </div>
                {/*  reviews section */}
                <div className=" d_main_panel_review ">
                  <div className="d_right_panal box_padding">
                    <div className="d_title_box">
                      <h5 className="d_main_title">What our customers say </h5>
                      <p className="d_main_sub_title">
                        What Our Happy Client Says
                        {/* <span className="d_line"></span>
                        <span className="d_round"></span> */}
                      </p>
                      <Review />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
      
      <Modal
          show={show2}
          onHide={handleClose2}
          aria-labelledby="contained-modal-title-bottom"
        >
          <Modal.Header closeButton>
            <h5>{temp.itemname}</h5>

            {/* <Button onClick={handleClose2} id="btn-modal-footer" style={{backgroundColor:"#a2c760" , width:"75px"}}>Add</Button> */}
            <br />
          </Modal.Header>
          <h6
            style={{
              marginBottom: "-10px",
              paddingTop: "17px",
              marginLeft: "2%",
            }}
          >
           Select your size.
          </h6>
         
          <Modal.Body>
            <div
              className="row"
              style={{ overflow: "scroll", height: "400px" }}
            >
              <div className="row">

                {extraItem?.map((addonGroup, index) => (
                  <div key={index}>
                    <p className="d_main_title d_box">
                      {addonGroup.addongroup_name}
                    </p>
                    <h6>you can choose any one option </h6>
                    {addonGroup?.addongroupitems?.map((addonItem, index) => (
                      <div className="row mt-2" key={index}>
                        <div className="col">
                          <div class=" custom-control " style={{width:"100%"}}>
                            <input
                              type="checkbox"
                              value={addonItem}
                              name="size-check"
                              checked={selectedAddons === addonItem}
                              onChange={(e) => {
                                onUpdateField(e, addonItem);
                              }}
                            />
                            <label class="custom-control-labl" style={{marginRight:"5%"}}>
                              {addonItem.addonitem_name}
                            </label>
                            <span class=" adonPrice">
                              Rs {addonItem.addonitem_price}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}

                {/* <br />
                <p className="d_main_title d_box">T-shirt type</p>
                <h6>you can choose any one option </h6>
                <div className="row">
                  <div className="col">
                    <div class=" custom-control ">
                      <input type="checkbox" />
                      <label class="custom-control-label">Men</label>
                      <span class=" adonPrice">Rs 0.00</span>
                    </div>
                  </div>
                  <div className="col mb-4">
                    <div class=" custom-control ">
                      <input type="checkbox" />
                      <label class="custom-control-label">Women</label>
                      <span class=" adonPrice">Rs 0.00</span>
                    </div>
                  </div>
                  <div style={{ borderBottom: "double" }}></div>
                </div> */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              variant="secondary"
              onClick={() => {
                onAdd(temp, selectedAddons);
                if(buyNow && buyNow.target.name==="buy_now"){
                  
                  navigate('/cart');  
                   }
                handleClose2();
              }}
              className="add-on-more-btn"
            >
              Add
            </button>
          </Modal.Footer>
        </Modal>
    </>
  );
};
export default Merchandise;
