import React, { memo, useEffect, useMemo, useState } from 'react'
import '../style_web/styleDoolally.css'
import Review from '../Sliders/Review'
import Header from '../components/HeaderWeb'
import Footer from '../components/FooterWeb'
import Helper from '../utils/Helper'
import { format, isValid } from 'date-fns'
import { Link } from 'react-router-dom'
import TwitterIcon from '@material-ui/icons/Twitter'
import { useNavigate } from 'react-router-dom'
import { atcb_action } from 'add-to-calendar-button'
import 'add-to-calendar-button/assets/css/atcb.css'

import {
  getRequestOptions,
  apiBaseUrl,
  homePageApiUrl,
  fBDImageBaseUrl,
  eventImageBaseUrl
} from '../config/constant'
import {
  setUserCurrLoc,
  getUserCurrLoc,
  getUser,
  getToken,
  removeUserSession
} from '../utils/UserAuthenticate'
import { showCorrectImage, numberWithCommas } from '../utils/Common'
import { config } from '@fortawesome/fontawesome-svg-core'
import HomeViewGallery from '../components/homeViewGallery'

const Doolally = () => {
  const [twitterView, settwitterView] = useState([])
  const [whatsOnTap, setwhatsOnTap] = useState([])
  const [location, setLocation] = useState([])
  const [hangout, setHangout] = useState([])
  const [slidderImages, setSliderImages] = useState([])
  const [whatHappingWeek, setwhatHappingWeek] = useState([])
  const [testimonial, setTestimonial] = useState([])
  const [isloading, setIsloading] = useState(false)
  const navigate = useNavigate()

  const getData = async () => {
    try {
      setIsloading(true)
      // here Api call for Home page
      let result = await fetch(apiBaseUrl + homePageApiUrl, getRequestOptions)
      if (result) {
        result = await result.json();
        settwitterView(result.response.result.twitterView);
        setwhatsOnTap(result.response.result.whatsOnTap);
        setLocation(result.response.result.location);
        setHangout(result.response.result.hangout);
        setwhatHappingWeek(result.response.result.whatHappingWeek);
        setTestimonial(result.response.result.testimobial);
      
        let hangoutImgArrayByType = [];
      
        // Get the hangout types present in the result.response.result.hangout
        const validHangoutTypes = result.response.result.hangout.map(item => item.type);
      
        // Organize hangout images by type and filter to match hangout types
        result.response.result.hangoutImgList.forEach(hangout => {
          const { type, ...rest } = hangout;
      
          // Only proceed if the type exists in the valid hangout types
          if (validHangoutTypes.includes(type)) {
            // Find the index of the hangout type in the array
            const index = hangoutImgArrayByType.findIndex(item => item && item.type === type);
      
            if (index === -1) {
              // If the type is not found, create a new entry
              hangoutImgArrayByType.push({ type, data: [rest] });
            } else {
              // If the type exists, append the new image data to it
              hangoutImgArrayByType[index].data.push(rest);
            }
          }
        });
      
        // Set the updated slider images to match the filtered hangout types
        setSliderImages(hangoutImgArrayByType);
      
        setTimeout(() => {
          setIsloading(false);
        }, 500);
      }
      
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    Helper.checkCurrentUserLegalAge().then(res => {
      if (res.legalAge === 'No') {
        // navigate("/homepage?redirect_url=/")
        navigate('/homepage')
      }
    })
    if (!getUserCurrLoc()) {
      setUserCurrLoc()
    }
    getData()
    //removeUserSession();
  }, [])

  const config = {
    name: 'Reminder to star the add to calendar button repo',
    description:
      'Check out the maybe easiest way to include add to calendar buttons to your website at:<br>→ [url]https://github.com/add2cal/add-to-calendar-button[/url]',
    startDate: '2023-01-14',
    endDate: '2023-01-18',
    options: ['Google', 'iCal'],
    timeZone: 'Europe/Berlin',
    trigger: 'click',
    iCalFileName: 'Reminder-Event'
  }

  return (
    <>
      <div className='container-fluid'>
        <Header />
        <section className='d_main_panel ' style={{ margin: '0 1%' }}>
          <div className='container-fluid'>
            <div className='row gx-5 '>
              <div className='col mb-4'>
                <div
                  className='d_left_pana box_padding gray_bg_color'
                  style={{ height: '700px', overflow: 'scroll' }}
                >
                  <h5 className='d_main_title'>What's on tap </h5>
                  <p className='d_main_sub_title'>
                    {/* Citrusy Witbier, Dark Decadent Stouts, Fruity Cider */}
                    Order craft brews online, home delivery across Mumbai & Pune within 2 hours
                    {/* <span className='d_line'></span>
                    <span className='d_round'></span>
                    <span className='d_round'></span>
                    <span className='d_round'></span> */}
                  </p>
                  <div className='scrollbar' style={{ overflow: 'scroll' }}>
                    <div className='row gy-2 gx-4'>
                      {/* here the Api render on home page for whats on tap */}
                      {whatsOnTap.map((item, index) => (
                        <div key={index} className='col-md-6 ' id='whatsonTap'>
                          <Link to='/beer'>
                            {' '}
                            <div className='d_product_box'>
                              <figure
                                className='d_product_img mb-0'
                                key={index}
                              >
                                <img
                                  src={
                                    fBDImageBaseUrl + 'thumb/' + item.filename
                                  }
                                  alt=''
                                />
                              </figure>
                              <p className='d_product_title'>{item.itemName}</p>
                              <a className='d_overlay'>
                                <p>Explore More</p>
                              </a>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 mb-4 '>
                <div
                  className=' d_midile_panal gray_bg_color pt-3 '
                  style={{ height: '192px', marginBottom: '20px' }}
                >
                  <div
                    className='location-section'
                    id='margin_home'
                    style={{ margin: '0 !important' }}
                  >
                    <div className='d-flex justify-content-around'>
                      <div className='adj-location'>
                        <Link to='/beer'>
                          <img
                            src={
                              process.env.PUBLIC_URL + '/img/BuyNow_icon.png '
                            }
                            loading='lazy'
                          />
                          <h5> Buy now</h5>
                        </Link>
                      </div>
                      <div className='adj-location'>
                        <Link to='/events'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              '/images/attend-an-event.png'
                            }
                            loading='lazy'
                          />
                          <h5> Events </h5>
                        </Link>
                      </div>
                      <div className='adj-location'>
                      <Link to='/locations'>
                    
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              '/images/locationHome.png'
                            }
                            loading='lazy'
                          />
                          <h5> Locations</h5>
                          </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className=' box_padding gray_bg_color scrollbar'
                  // id="hm-hangout"
                  style={{ height: '488px' }}
                >
                  <div className='d_title_box'>
                    <h5 className='d_main_title'>
                      What's happening at Doolally
                    </h5>
                    <p className='d_main_sub_title'>
                    All-day dining, pet-friendly taprooms brimming with beer, board games and tom-foolery
                    {/* Attend a workshop, host an event, join a community. */}
                      {/* <span className='d_line'></span>
                      <span className='d_round'></span>
                      <span className='d_round'></span>
                      <span className='d_round'></span> */}
                    </p>
                    <div className='d_hangout_box container-fluid'>
                      <HomeViewGallery
                        hangout={hangout}
                        slidderImages={slidderImages}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3'>
                <div
                  className='d_right_panal box_padding whatHappingWeek'
                  id='hm-whatshappenin'
                  style={{ height: '375px' }}
                >
                  <div className='d_title_box'>
                    <h5 className='d_main_title'>What's happening this week</h5>
                    <p className='d_main_sub_title'>
                    Attend a workshop, host an event, join a community
                      {/* <span className='d_line'></span>
                      <span className='d_round'></span>
                      <span className='d_round'></span>
                      <span className='d_round'></span> */}
                    </p>
                  </div>
                  <div style={{ overflowY: 'scroll', height: '200px' }}>
                    {whatHappingWeek.map((item, id) => (
                      <div key={id} className='d_days_box mb-4'>
                        {item.eventDate && (
                          <h3 className='d_days_title'>
                            {format(new Date(item.eventDate), 'iiii')}
                          </h3>
                        )}
                        <div className='d_days_iner_box mb-3'>
                          <div>
                            <figure className='mb-0'>
                              <Link
                                to={
                                  '/event-details/' +
                                  item.eventSlugs +
                                  '__' +
                                  item.eventIds
                                }
                              >
                                <img
                                  src={showCorrectImage(
                                    item.eventImage,
                                    'w_80'
                                  )}
                                  alt=''
                                />
                              </Link>
                            </figure>
                          </div>
                          <Link
                            to={
                              '/event-details/' +
                              item.eventSlugs +
                              '__' +
                              item.eventIds
                            }
                          >
                            <p className='d_days_subtitle '>
                              {item.eventNames}
                            </p>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className=' d_main_panel_review '>
                  <div
                    className='d_right_panal box_padding'
                    style={{ height: '310px' }}
                  >
                    <div className='d_title_box'>
                      <h5 className='d_main_title'>What others have to say</h5>
                      <p className='d_main_sub_title'>
                      Reviews, recommendations
                        {/* <span className='d_line'></span> */}
                      </p>
                      <Review />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}
export default memo(Doolally)
