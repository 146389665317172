import React, { useState, useContext, useEffect } from "react";
import Header from "../components/HeaderMob";
import NavLink from "react-bootstrap/esm/NavLink";
import Footer from "../components/FooterMob";
import FnbContext from "../contextApi/FnbContext";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Cart = () => {
  const navigate = useNavigate();
  const {
    cartItems,
    subTotalfunction,
    handleChangeIncDec,
    resturantMiniAmount,
    subTotal,
    deliveryCharge,
  } = useContext(FnbContext);
const [error,setError]=useState('')
  const handleAmount = () => {
    subTotalfunction();
  };
  useEffect(() => {
    if (cartItems && cartItems.length === 0) {
      navigate("/beer");
    }
  }, []);
  return (
    <>
      <div className=" container-fluid d_margin mb-4 pb-4 ">
        <Header />

        <p className="d_main_title">Your Ordered {cartItems.length>1?"items":"item"}</p>
        <p className="d_main_sub_title">
          Ordered {cartItems.length>1?"items":"item"} 
          {/* <span className="d_line"></span>
          <span className="d_round"></span>
          <span className="d_round"></span>
          <span className="d_round"></span> */}
        </p>

        {/* {cartItems.map((item,index)=>(
  <div className="row mt-2">
          <div className="col-3">
            <img
              src={process.env.PUBLIC_URL + "/images/h9.png"}
              style={{ width: "80px" }}
            />
          </div>
          <div className="col-4">
            <strong style={{fontSize:"12px"}}> {item.itemname.substring(0, 15)}</strong>

            <div className="d-flex ">

              <div className="g-add" id="d_btndiv9">
                <form className="d-flex align-items-center ">
                  <div className="form">
                    <div
                      className="value-button"
                      id="decrease"
                      onClick={()=>{handleChangeIncDec(item,-1)}}
                      value="Decrease Value"
                    >
                      -
                    </div>
                    <input
                      type="number"
                      className="number"
                      id="number"
                      value={item.qty}
                      style={{ width: "20px" }}
                    />
                    <div
                      className="value-button"
                      id="increase"
                      onClick={()=>{handleChangeIncDec(item,1)}}
                      value="Increase Value"
                    >
                      +
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-3 text-center " style={{paddingTop:"7%"}}>
         
            <strong >₹ {item.price}</strong>
           
          
        
        
          </div>
          <div className="col-2" style={{paddingTop:"7%"}}>
          <DeleteOutlineIcon   style={{
                backgroundColor: "#f7eef3",
                color: "#c86e7a",
                border: "none",
                borderRadius: "5px",
            
              }}

              onClick={()=>{removeItem(item)}}
              />
          </div>
        </div>
))}  */}
        <div
          style={{ maxHeight: "300px", overflowY: "auto", paddingRight: "2%" }}
        >
          {cartItems.map((item, index) => (
            <div
              className="d-flex"
              style={{
                border: "1px solid #d4d4d4",
                borderRadius: "5px",
                // boxShadow: "2px 5px 5px 5px #f5f5f5",
                position: "relative",
                marginBottom: "10px",
                padding: "2%",
              }}
              key={index}
            >
              <img
                src={
                  item.item_image_url
                    ? item.item_image_url
                    : process.env.PUBLIC_URL + "/images/pro7.png"
                }
                style={{ width: "60px", marginRight: "6.6%" }}
              />

              <div>
                <strong>{item.itemname}</strong>
                <span
                  className="wrapperr mt-1 mx-1"
                  style={{
                    minWidth: "120px",
                    maxWidth: "120px",
                    height: "30px",
                  }}
                >
                  <span className="minus">
                    <RemoveIcon onClick={() => handleChangeIncDec(item, -1)} />
                  </span>
                  <span className="num">{item.qty}</span>
                  <span className="plus">
                    <AddIcon onClick={() => {setError('');handleChangeIncDec(item, 1)}} />
                  </span>
                </span>
              </div>
              <div
                style={{ position: "absolute", right: "12px", bottom: "12px" }}
              >
                <strong>₹ {item.price}</strong>
              </div>
              {/* <DeleteOutlinedIcon onClick={()=>{removeItem(item)}} style={{marginTop:"23px"}}/> */}
            </div>
          ))}
        </div>

        <div
          className=" box_paddin gray_bg_color mt-4 pt-4 "
          style={{ padding: "0% 5% 0% 5%" }}
        >
          <div className="d_title_box">
            <div className="d_title_box">
              <p className="d_main_sub_title">
                Bill Details
                {/* <span className="d_line"></span>
                <span className="d_round"></span>
                <span className="d_round"></span>
                <span className="d_round"></span> */}
              </p>

              <div className="d-flex justify-content-between">
                <p>Amount</p>
                <p>Rs {subTotal - deliveryCharge}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p>Discount</p>
                <p> 0 </p>
              </div>
              <div className="d-flex justify-content-between">
                <p>Delivery charges</p>
                {subTotal - deliveryCharge == -0 ? (
                  "0"
                ) : deliveryCharge ? (
                  <p>{deliveryCharge}</p>
                ) : (
                  <p>Free</p>
                )}
              </div>
              <div className="d-flex justify-content-between">
                <p>Total Amount</p>
                <p>Rs {subTotal}</p>
              </div>
            </div>
          </div>
        </div>
        {error&&<p className="errorMsg text-center">{error}</p>}
        <div className=" ds-none row g-2 mb-4">
          <span
            className="mt-3 mb-3 text-center "
            style={{
              backgroundColor: "#A2C760",
              color: "white",
              borderRadius: "5px",
              height: "30px",
              width: "80%",
              marginLeft: "10%",
              paddingTop: "1%",
            }}
          >
         
<div onClick={() => {
  if (resturantMiniAmount <= subTotal) {
    navigate('/user-order-summary');
  } else {
    setError(`Minimum order amount should be ${resturantMiniAmount}`)
  }
}}>
  Proceed to checkout
</div>

          </span>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Cart;
