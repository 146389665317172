import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'

const HomeViewGallery = props => {
  const { slidderImages ,hangout} = props
  const sliderImages=slidderImages

  // State to handle modal visibility and the selected group
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0) // Tracks the group type (Pets, Food, etc.)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0) // Tracks the image index within that group

  // Opens the modal for the selected group and image
  const openModal = (groupIndex, imageIndex) => {
    setSelectedGroupIndex(groupIndex)
    setSelectedImageIndex(imageIndex)
    setModalIsOpen(true)
  }

  // Closes the modal
  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <div>
      {/* Display thumbnails for each group */}
      <div className='row gy-4 '>
        {sliderImages&&sliderImages?.map((group, groupIndex) => (
          <div key={groupIndex} className='col-6 col-md-4'>
            <a data-bs-toggle='modal' data-bs-target='#staticBackdrop'>
              <div className='d_product_box'>
                <figure className='d_product_img mb-0'>
                  {/* Display the first image of each group as a thumbnail */}
                  <img
                    src={group.data[0].hangoutImage}
                    alt={`Group ${group.type}`}
                    onClick={() => openModal(groupIndex, 0)} // Open the modal on click
                  />
                </figure>
                <p className='d_product_title' style={{ marginBottom: '0rem' }}>
                  {group.type}
                </p>
              </div>
            </a>
          </div>
        ))}
      </div>

      {/* Modal to show the carousel of images for the selected group */}
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={closeModal}>
            <Carousel
              currentIndex={selectedImageIndex}
              views={sliderImages[selectedGroupIndex].data.map(item => ({
                source: item.hangoutImage,
                caption: item.hangoutName || 'Default Caption'
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}

export default HomeViewGallery
