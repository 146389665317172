import React, { useState, useEffect } from "react";
import Header from "../components/HeaderWeb";
import Footer from "../components/FooterWeb";
import { apiBaseUrl } from "../config/constant";
import axios from "axios";


const AboutUs = () => {
    const [pageData, setPageData] = useState([]);
    const getData = () =>{
        const apiUrl = apiBaseUrl + "common/get-cms-data";

        axios.post(apiUrl,{
            page : "About Us"
        })
        .then((response) => {
            setPageData(response.data.response.result);
        })
        .catch((error) => {
            
            console.error("API error:", error);
        });
    }
     useEffect(() => {
        getData();
  }, []);
    return (
        <div className="container-fluid">

            <Header />
            <div className="d_main_panel">
                <div className="container box_padding d_title_box" style={{ backgroundColor: "white" }}>

                    <h1 className="text-center mb-4"> {pageData.page?pageData.page:"About us"}</h1>
                   <div dangerouslySetInnerHTML={ { __html: pageData?.content}}>

                   </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default AboutUs;
